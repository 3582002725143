//console.log( 'init zoella js' );

var $ = jQuery;

var UP = 'up';
var DOWN = 'down';
var MOBILE = 'mobile';
var TABLET = 'tablet';
var DESKTOP = 'desktop';
var WIDE = 'wide';

var enableFooter = true;
var footerSeen = false;
var displaySize = '';

var timeout;
var $window;
var $document;
var $body;
var windowHeight;
var $stickyFooter;
var $stickyHeader;
var $paternoster;

var footerHeight;
var footerTop;
var footerFromBottom;
var documentHeight;
var dwh;

var $debugArea;
var $primary;
var $sideBar;
var $subCat;

var primaryBottom;
var sidebarBottom;

var modal;
var $animationHandle;

//stuff to do on ready
$(function() {
    $window = $(window);
    $document = $(document);
    $body = $('body');

    $stickyFooter = $( '#next-prev-post' );
    $stickyHeader = $( '#main-nav' );
    $paternoster = $( '.paternoster' );

    $primary = $('#primary');
    $sideBar = $('#side-bar');
    $subCat = $('.sub-cat, .sub-years');

    $modal = $('#modal');
    $animationHandle = $('.animation-wrapper');

    $stickyHeader.sticky( { responsiveWidth: true, getWidthFrom: 'body' } );
    //$sideBar.sticky( { topSpacing: 90, bottomSpacing: 200 } );

    $('input, textarea').placeholder();

    responsiveYoutube();

    onPageReady();

    /****************************************************
    * Seasonal specific stuff
    **************************************************/

    // Summer options
    if($body.hasClass('summer')){
        $('h1').addClass(randomLogo([ 'beachball', 'sun', 'sandcastle', 'watermelon', 'drink', 'lemon' ]));
    }

    // Easter options
    if($body.hasClass('easter')){
        $('h1').addClass('easter');
        $('#fairy-lights').addClass('easter');
        loadBunny();
    }

    // Christmas options
    if($body.hasClass('xmas')){
        $('h1').addClass('xmas');
        $('body').addClass('snow');
        $('#fairy-lights').addClass('xmas');

        // Fall back on 2015 styles but apply yearly variants if selected
        if($body.hasClass('year-2016')){
            $('h1').addClass('xmas-2016');
            $('h1').addClass(randomLogo(['pudding', 'star', 'robin']));
            $('#fairy-lights').addClass('xmas-2016');
            addSanta();
        }
        //addSnow();
        loadFairies();
    }

    // Valentines options
    if($body.hasClass('valentines')){
        $('h1').addClass('valentines');
        $('#fairy-lights').addClass('valentines');
        loadCupid();
    }

    // Halloween options
    if($body.hasClass('halloween')){
        $('h1').addClass('halloween');
        $('#fairy-lights').addClass('halloween');
        $('.head').prepend(
            '<div class="spider-wrapper"><img id="spider" aria-hidden="true" src="/wp-content/themes/zoella/img/spider.png"></div>'
            );
        addGhost();
    }

    // Comic Relief options
    if($body.hasClass('comicrelief')) {
        $('h1').addClass('red-nose');
    }

    /****************************************************
    * Additional body class specific stuff
    **************************************************/

    if($body.hasClass('snow') && (displaySize != MOBILE)){
        addSnow();
    }

});

//stuff to do on load
$( window ).load(function(){
    //console.log( "on window load" );
    onPageLoaded();
});

function responsiveYoutube() {
    $('article iframe' ).each(function() {
        $iframe = $(this);

        // only manage iframes that are youtube related
        if ($iframe.attr('src').indexOf('youtube.com') > -1) {
            var url = $iframe.attr('src' ),
                html = '<style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class="embed-container"><iframe src="{source}" frameborder="0" allowfullscreen></iframe></div>';

            $iframe.replaceWith(html.replace('{source}', url));
        }
    });
}


function onPageReady() {
    initHeaderSearch();
    initSearchButton();
    initKeyboard();

    //if ($('.popular-posts-slider')) initPopularSlider();

    if ( $body.hasClass( 'home' ) ) {
        //initPaternoster( '.paternoster' );
        //$paternoster.paternoster( {} );

        if( $body.hasClass('xmas') && $body.hasClass('year-2016') && $body.hasClass('gift-enabled')) {
          addGiftContainer()
        }

    }

    if ($('#primary' ).hasClass( 'about-me' )) {
        $( '.gallery' ).fadeyGallery( { 'cheese': true } );
    }

    $(document).on('mousedown touchdown', 'a, button, input, select, .button, textarea', function(e) {
        $(this).addClass('no-focus');
    }).on('blur touchend', function(e) {
        $(this).removeClass('no-focus');
    });

    /*
     $body.prepend( '<div id="debug"><p></p></div>' );
     $debugArea = $('#debug');
     $debugArea.css({width: 400, height:200, 'z-index':9999, position: 'fixed', background: 'white', border: '1px solid black' });
     */

    if ($body.hasClass('single')) {
        initCaptionClasses();
        //initScrollElements();
    }

    //find pairs of '.size-half-full-wide' and pair them wrapped in a div
    if ( $('.size-half-full-wide').length > 1 ) {
        var numpairs = Math.floor($('.size-half-full-wide').length / 2);
        for (var i = 0; i < numpairs; i++) {
            var $left = $('.size-half-full-wide.alignleft').eq(i);
            var $right = $('.size-half-full-wide.alignright').eq(i);

            var $pair = $left.add( $right );
            $pair.wrapAll( '<div class="sidebyside"></div>' );
        }
    }

    $('button.twitter').on('click', function(e) { e.preventDefault(); sharer.twitter( $(this) );  $(this).blur();});
    $('button.facebook').on('click', function(e) { e.preventDefault(); sharer.facebooksimple( $(this) );  $(this).blur(); });
    $('button.gplus' ).on( 'click', function(e) {e.preventDefault(); sharer.gplus( $(this) );  $(this).blur();} );
    //displaySize = ;

    //console.log( 'getDisplaySize', getDisplaySize() );

    changeDisplaySize( getDisplaySize() );

    //window.viewportUnitsBuggyfill.init();

}

function onPageLoaded() {

    // dropInFairyLights();
    initScrollElements();

}

function dropInFairyLights() {
    var $lights = $('#fairy-lights');
    $.cssEase['bounce'] = 'cubic-bezier(0,1,0.5,1.3)';
    $lights.transition( { top: 0, rotate: 0 }, 300, 'bounce' );
}

function getDisplaySize() {

    var display = '';
    if ( $('.size.mobile').css('display') == "block" ) display = MOBILE;
    if ( $('.size.tablet').css('display') == "block" ) display = TABLET;
    if ( $('.size.desktop').css('display') == "block" ) display = DESKTOP;
    if ( $('.size.wide').css('display') == "block" ) display = WIDE;
    return display;
}

function initPopularSlider() {
    $('.popular-posts-slider').shlider({
        'container': '.container',
        'slide': '.popular-post',
        'navIncludeNumSlides' : false,
        'singleSlideMode': false,
        'autoSlide' : false
    });
}

function initCaptionClasses() {
    $('.wp-caption').each( function( i ) {
        var $this = $( this );
        var $img = $this.find('img');
        var width = $img.attr('width');
        if ($img.hasClass('size-medium')) {
            $this.addClass('size-medium' ).css('width', '');
        }
        if ($img.hasClass('size-large')) {
            $this.addClass('size-large').css('width', '');
        }
        if ($img.hasClass('size-small-inline')) {
            $this.addClass('size-small-inline');
        }
        if ($img.hasClass('size-full')) {
            $this.addClass('size-full' ).css('width', '');
        }
        if ($img.hasClass('size-full-wide')) {
            $this.addClass('size-full-wide' ).css('width', '');
        }
    });

    $( 'section.page img.size-full-wide' ).each( function() {
        var $this = $( this );
        //var $container = $this.parent();
        //$container.addClass("image-parallax");

        $this.wrap( '<div class="image-parallax"></div>' );

        $this.addClass('nopin');

    });
}

function initHeaderSearch() {
    $('.quick-search.header input').on({
        focus: function(e) {
            //console.log( 'on form focus' );
            window.clearTimeout(timeout);
            $('.quick-search.header button, .quick-search.header input').addClass('active');
        },
        blur: function(e) {
            //console.log( 'on form blur' );
            timeout = window.setTimeout(function() {
                $('.quick-search.header button, .quick-search.header input').removeClass('active');
            }, 5000);
        }
    }).val('');

}

function disableHeaderSearch() {
    //console.log( 'disableHeaderSearch' );
    $('.quick-search.header input').unbind( 'focus' ).unbind( 'blur' );
    window.clearTimeout( timeout );
}

function initSearchButton() {

    //console.log( 'initSearchButton' );

    $('input').keyup(function(){
        if ( $( this ).val().length > 0 ) {
            $( this ).parent().find( 'button' ).prop( 'disabled', false );
        } else {
            $( this ).parent().find( 'button' ).prop( 'disabled', true );
        }
    });
}



function initKeyboard() {

    $( '#main-nav' ).find( 'button' ).on( 'keydown', menuKeyboardHandler );
    //to make sure any keyb opened menus are closed on mouse
    $( '#main-nav li' ).on( 'mouseenter', menuMouseEnterHandler );
    $( '.quick-search.header input' ).on( 'focus', menuMouseEnterHandler );
    $( '.share-container' ).on( 'keydown', openSharePanel);
}

function openSharePanel( e ) {

    //console.log( e.keyCode );
    //console.log( e.target );
    var $parent = $( e.target ).parent();
    var parent_index = $parent.index();

    if ( e.keyCode == 13 || e.keyCode == 32 ) {
        $( '.share-container' ).toggleClass( 'open' );
    }
    if ( e.keyCode == 9 ) {
        var shifted = e.shiftKey;
        //console.log( '$parent: ', $parent );
        //console.log( 'parent_index: ', parent_index );
        //console.log( '$parent.hasClass: ', $parent.hasClass('share-container') );
        if (!$parent.hasClass('share-container')) {
            if ( shifted ) {
                if ( parent_index === 0 ) $( '.share-container' ).removeClass( 'open' );
            } else {
                if ( parent_index == ($parent.parent().children().length - 1) ) $( '.share-container' ).removeClass( 'open' );
            }
        }
    }
}

function menuKeyboardHandler(e) {

    //console.log( e.keyCode );
    //console.log( e.target );

    var $button = $( e.target);
    var $parent_li = $button.parent();

    if ( e.keyCode == 13 || e.keyCode == 32 ) {

        $( '.sub-menu.open' ).removeClass( 'open' );
        $('.sub-open').removeClass( 'sub-open' );

        var $submenu = $( e.target ).parent().find( '.sub-menu' );
        $submenu.addClass( 'open' );
        $parent_li.addClass( 'sub-open' );
        //console.log( '$submenu: ', $submenu );
    }

    //$button
    if ( e.keyCode == 9 ) {
        var parent_li_index = $parent_li.index();
        var shifted = e.shiftKey;
        var number_of_siblings = $parent_li.parent().children().length;
        //console.log( 'parent_li_index: ', parent_li_index );
        //console.log( 'numner_of_siblings: ', number_of_siblings );
        if ( shifted ){
            if ( parent_li_index === 0 ) {
                $( '.sub-menu.open' ).removeClass( 'open' );
                $('.sub-open').removeClass( 'sub-open' );
            }
        }
    }

}


function menuMouseEnterHandler( e ) {
    $( '.sub-menu.open' ).removeClass( 'open' );
    $('.sub-open').removeClass( 'sub-open' );
}

function initScrollElements() {

    if (window.addEventListener) {
        window.addEventListener('scroll', scroller, false);
        window.addEventListener('resize', resizer, false);
    } else if (window.attachEvent) {
        window.attachEvent('onscroll', scroller);
        window.attachEvent('onresize', resizer);
    }

    documentHeight = $document.height();
    windowHeight = $window.height();
    dwh = documentHeight - windowHeight;

    if ($body.hasClass('single')) {
        footerHeight = $stickyFooter.outerHeight( true );
        footerTop = $stickyFooter.offset().top;
        footerFromBottom = documentHeight - footerTop - footerHeight;
        $stickyFooter.wrap( '<div class="sticky-wrapper" style="height: ' + footerHeight + 'px"></div>' );

        //console.log( 'primary height: ', $primary.height() );
        //console.log( 'primary top: ', $primary.offset().top );
        //console.log( 'documentHeight: ', documentHeight );

        primaryBottom = ($primary.height() + $primary.offset().top);
        sidebarBottom = ($sideBar.height() + 90);

        $( '.image-parallax' ).each( function ( i ) {
            //console.log( '.image-container: this: ', this );
            //console.log( 'this: ', this );
            var $ele = $( this );
            var offset = ($ele.offset().top - $ele.height());
            $( this ).attr( 'data-offset', offset );
            var height = $ele.height();
            $( this ).attr( 'data-height', height );
        } );

        scroller();
    }

}

function changeDisplaySize( targetSize ) {

    //console.log( 'Display changed to: ', targetSize );
    //console.log( 'Display changed from: ', displaySize );

    if ( targetSize == MOBILE ) {
        this.enableMobile();
        $stickyHeader.unstick();
        if ($sideBar) $sideBar.unstick();
    } else {
        if ( displaySize == MOBILE || displaySize === '' ){
            this.disableMobile();
            $stickyHeader.unstick();
            $stickyHeader.sticky( { responsiveWidth: true, getWidthFrom: 'body' } );
            if ($sideBar) $sideBar.sticky( { responsiveWidth: true, getWidthFrom: '#share', topSpacing: 90, bottomSpacing: 200 } );
        }
    }

    displaySize = targetSize;


}

function enableMobile() {
    $('#open-menu' ).on('click',  openMobileNav);
    //$('.mobile-nav .close' ).on('click',  closeMobileNav);

    disableHeaderSearch();
    if ($paternoster) $paternoster.paternoster().off();

    if ($subCat) {
        $subCat.on('click', openCloseMobileCatNav.bind(this));
    }

    //console.log( 'paternoster: ', $paternoster );
    //console.log( );
    //$( '.paternoster' ).paternoster(  ).stopAutoSlider();
    //$( '#main-nav' ).slideUp( 1 );

    //$('#site-header').sticky(  { responsiveWidth: true, getWidthFrom: 'body', topSpacing: -36 }  );

}

function addGiftContainer() {
    $paternoster.append('<div id="anim-gift" class="gift-container">');
    $('#anim-gift').on('click', openModal.bind(this));
    $('#modal-close').on('click', closeModal.bind(this));
}


function openModal() {
    if (!$modal.hasClass('enabled')) {
      $modal.addClass('enabled');
      swapSrc();
    }
}

function closeModal(){
    if ($modal.hasClass('enabled')) {
      $modal.removeClass('enabled');
      swapSrc();
    }
}

function swapSrc() {
    var $dataSrc = $animationHandle.attr('data-src');
    var $src = $animationHandle.attr('src');

    $animationHandle.attr('data-src', $src);
    $animationHandle.attr('src', $dataSrc);
}

function disableMobile() {
    $('#open-menu' ).off('click',  openMobileNav);
    //$('.mobile-nav .close' ).off('click',  closeMobileNav);


    if ( $body.hasClass( 'nav-open' ) ) {
        closeMobileNav();
    }

    if ($subCat) {
        $subCat.unbind('click');
    }

    //$('#site-header').unstick();

    if ( $paternoster ) $paternoster.paternoster().on();

    $( '.main-nav' ).show();

    initHeaderSearch();

}

function openMobileNav() {
    //console.log( 'openMobileNav: ' );
    //$( 'nav.mobile-nav' ).show().transition( { left: '0%' } );
    //$body.addClass( 'nav-open' );
    $( '#main-nav' ).slideToggle();
    $( '#open-menu' ).toggleClass('open');


}

function closeMobileNav() {

    //$( 'nav.mobile-nav' ).hide();
    $body.removeClass( 'nav-open' );
    $( 'nav.mobile-nav' ).transition( { left: '-100%' }, 750, function() { $( this ).hide(); } );
}

function openCloseMobileCatNav() {
    $subCat.toggleClass( 'open' ).find( 'i' ).toggleClass( 'fa-angle-up' );

    if ($subCat.hasClass('open') ) {
        $subCat.find( 'i' ).removeClass( 'fa-angle-down' ).addClass( 'fa-angle-up' );
    } else {
        $subCat.find( 'i' ).removeClass( 'fa-angle-up' ).addClass( 'fa-angle-down' );
    }

}

function resizer() {
    if ($body.hasClass('single')) {
        //documentHeight = $document.height();
        //footerHeight = $stickyFooter.outerHeight();
        windowHeight = $window.height();
        //footerTop = $stickyFooter.offset().top;
        dwh = documentHeight - windowHeight;
    }

    if ( displaySize != getDisplaySize() ){
        changeDisplaySize( getDisplaySize() );
    }
}

function scroller() {

    //check doc height:
    if (displaySize != MOBILE) {

        if ( $document.height() != documentHeight ) {
            documentHeight = $document.height();
            dwh = documentHeight - windowHeight;
        }

        var scroll_top = $window.scrollTop();
        var from_bottom = dwh - scroll_top;
        var side_bar_offset = (scroll_top - (primaryBottom - sidebarBottom));


        $( '.image-parallax' ).each( function ( i ) {

            var $ele = $( this );
            var figure_from_top = (scroll_top - parseInt( $ele.attr( 'data-offset' ) )) - parseInt( $ele.height() );
            var figure_from_bottom = (figure_from_top + windowHeight);
            var pc_up_screen = (figure_from_bottom / (windowHeight + parseInt( $ele.height() )) * 100);
            if ( pc_up_screen > 0 && pc_up_screen < 100 ) {
                $ele.find( 'img' ).css( {
                    top: pc_up_screen + '%',
                    'transform': 'translateY(-' + ( pc_up_screen ) + '%)'
                } );
            }
        } );
        /*
        var figure_offset = ($('.image-container').offset().top - $('.image-container' ).height()) ;
        var figure_from_top = (scroll_top - figure_offset) - $('figure.size-full' ).height();
        var figure_from_bottom = (figure_from_top + windowHeight);
        var pc_up_screen = figure_from_bottom / (windowHeight + $('figure.size-full' ).height()) * 100;

        if (pc_up_screen > 0 && pc_up_screen < 100) {
        $( 'figure.size-full' ).find( 'img' ).css( { top: pc_up_screen + '%' } );
        }
        */

        /*
        var output = '<p>';

        output += 'scrollTop: ' + scroll_top + '<br/>';

        output += 'from_bottom: ' + from_bottom + '<br/>';
        output += 'footerFromBottom: ' + footerFromBottom + '<br/>';
        output += 'primaryBottom: ' + primaryBottom + '<br/>';
        output += 'dwh: ' + dwh + '<br/>';
        output += 'dwh - primaryBottom: ' + (dwh - primaryBottom) + '<br/>';
        //output += 'sidebar offset: ' + side_bar_offset + '<br/>';

        output += '</p>';
        $debugArea.html( output );
        */


        if ( side_bar_offset > 0 ) {
            var side_bar_top = (90 - side_bar_offset);
            $sideBar.css( { 'top': side_bar_top } );
        } else {
            if ( $sideBar.parent().hasClass( 'is-sticky' ) ) $sideBar.css( { 'top': 90 } );
        }

        //TO RE-ENABLE FOOTER

        /*
        if ( from_bottom - footerFromBottom > 80 ) {
            //
            //console.log( 'stick it' );
            if ( !$stickyFooter.hasClass( 'stuck' ) && footerSeen ) {
                $stickyFooter.css( {
                    'position': 'fixed',
                    'bottom': 0,
                    'margin-bottom': 0
                } ).addClass( 'stuck' );
            }
        } else {
            //console.log( 'unstick it' );
            footerSeen = true;
            if ( $stickyFooter.hasClass( 'stuck' ) ) {
                $stickyFooter.css( {
                    'position': '',
                    'bottom': '',
                    'margin-bottom': ''
                } ).removeClass( 'stuck' );
            }
        */
    }

}

/****************************************************
 * Seasonal specific functions
 **************************************************/

function loadFairies()
{
    $('.fairy1, .fairy2').parent('a').addClass('fairy');
    $('.fairy1').before('<img src="./wp-content/themes/zoella/img/fairy1.png" class="fairyone">');
    $('.fairy2').before('<img src="./wp-content/themes/zoella/img/fairy2.png" class="fairytwo">');
}

function addSanta()
{
  if(!$('#santaFrame').length){
    $body.append('<div id="santaFrame" class="santaframe"></div>');
  }

  var santaFrame = $('#santaFrame');

  var w = santaFrame.width();
  var h = santaFrame.height();
  var santaCurve = {
      start: {
          x: w,
          y: ((Math.random() * (h / 8)) + ((3 * h) / 4)),
          angle: 23,
          length: 0.73
      },
      end: {
          x: -475,
          y: (Math.random() * (h / 10)),
          angle: 36,
          length: 0.60
      }
  }

  loadAnimation(santaFrame, 'santaAnim', '/wp-content/themes/zoella/img/santas_sleigh.gif', santaCurve);
}

function addGhost()
{
    if(!$('#ghostFrame').length){
        $body.append('<div id="ghostFrame" class="ghostframe"></div>');
    }

    var ghostFrame = $('#ghostFrame');

    loadAnimation(ghostFrame, 'ghostAnim', '/wp-content/themes/zoella/img/ghost.png');

}

function loadAnimation(animFrame, animClass, asset, curveParams){
    var w = animFrame.width();
    var h = animFrame.height();

    // Default bezier curve if not defined
    var curveParams = (typeof curveParams !== 'undefined') ? curveParams : {
        start: {
            x: w,
            y: Math.floor(Math.random() * (h - 200)) + 200,
            angle: 23,
            length: 0.73
        },
        end: {
            x: -175,
            y: Math.floor(Math.random() * h) - 180,
            angle: 36,
            length: 0.60
        }
    };

    var assetEl = '<img id="animSlide" class="anim-slider ' + animClass + '" aria-hidden="true" src="' + asset + '">';
    if(!$('#animSlide').length){
        animFrame.append(assetEl);
    }
    if(!$('#animSlide').is(':animated')){
        $('#animSlide').animate({
            path: new $.path.bezier(curveParams)
        }, 7000, function() {
          this.remove();
          if($('#anim-gift')){
            $('#anim-gift').addClass('animated wobble');
          }
        });
    }

}

function addSnow()
{
    if(!$('.snowbutton').length){
        //console.log('adding button');
        $('#menu-social-menu').before(
            '<button class="snowbutton">Toggle Snow</button>'
        );
    }
    if(!$('.snowframe').length){
        //console.log('adding frame');
        $('body').append('<div class="snowframe"></div>');
    }

    snowEnabled = true;
    snowSmall = false;
    flakeCountHigh = 30;
    flakeCountLow = 10;

    if (Cookies.get('showsnow')) {
        snowEnabled = Cookies.get('showsnow');
        snowEnabled = (snowEnabled == 'true') ? true : false;
    }

    $('.snowbutton').text(snowEnabled ? 'Snow On' : 'Snow Off');

    if(snowEnabled) {
        loadSnow();
    }

    $('.snowbutton').on('click', function() {
        if (snowEnabled) {
            snowEnabled = false;
            $('.snowframe').snowfall('clear');
        } else {
            snowEnabled = true;
            loadSnow();
        }

        $('.snowbutton').text(snowEnabled ? 'Snow On' : 'Snow Off');
        Cookies.set('showsnow', snowEnabled, { expires: 30, path: '/' });
    });
}

function loadSnow()
{
    w = $('.snowframe').width();

    if (snowEnabled)
    {
        $('.snowbutton').text('Snow On');

        if (w < 768) {
            snowSmall = true;
            $('.snowframe').snowfall('clear').snowfall({
                shadow : true,
                round : true,
                minSize: 1,
                maxSize: 8,
                flakeCount: flakeCountLow,
            });
        } else if (w >= 768) {
            snowSmall = false;
            $('.snowframe').snowfall('clear').snowfall({
                shadow : true,
                round : true,
                minSize: 1,
                maxSize: 8,
                flakeCount: flakeCountHigh,
            });
        }

        $(window).resize(function() {
            w = $('.snowframe').width();

            if (!snowSmall && w < 768) {
                snowSmall = true;
                $('.snowframe').snowfall('clear').snowfall({
                    shadow : true,
                    round : true,
                    minSize: 1,
                    maxSize: 8,
                    flakeCount: flakeCountLow,
                });
            } else if (snowSmall && w >= 768) {
                snowSmall = false;
                $('.snowframe').snowfall('clear').snowfall({
                    shadow : true,
                    round : true,
                    minSize: 1,
                    maxSize: 8,
                    flakeCount: flakeCountHigh,
                });
            }
        });
    }
    else
    {
        $('.snowbutton').text('Snow Off');
    }
}

function loadCupid()
{
    $('.cupid1').parent('a').addClass('cupid');
    $('.cupid1').before('<img src="/wp-content/themes/zoella/img/cupid.png" class="cupidone">');
}

function loadBunny()
{
    $('.bunny').parent('a').addClass('bunny-container');
    $('.bunny').before('<a href="http://goo.gl/forms/rLwVSfv0ZA"><img src="/wp-content/themes/zoella/img/bunny.png" class="bunnyone"></a>');
}

function randomLogo( classes ) {

    var num = Math.floor(Math.random() * (classes.length));
    return classes[ num ];

}
